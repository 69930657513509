// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./bg.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".backimg[data-v-21be7a6a]{position:fixed;z-index:0;width:100%;height:100%;bottom:0;left:0;top:0;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%;z-index:1}.main-content[data-v-21be7a6a]{position:absolute;left:50%;top:50%;transform:translate(-50%,-50%);z-index:10;width:900px;height:500px}.header-t[data-v-21be7a6a]{height:120px;width:100%;text-align:center;margin:auto;color:#fff;font-weight:bolder}.header-t .logo-t[data-v-21be7a6a]{font-size:2.5rem!important}.header-t .logo-tips-t[data-v-21be7a6a]{font-size:1.2rem!important;height:80px;line-height:80px;font-weight:400}.page-content1[data-v-21be7a6a]{position:relative;width:100%;margin-top:30px}.page-content1-bk[data-v-21be7a6a]{position:absolute;background-color:#000;opacity:.3;width:900px;height:280px;z-index:100;border-radius:15px}.page-content-c[data-v-21be7a6a]{position:absolute;width:100%;height:280px;line-height:280px;text-align:center;margin:auto;z-index:105}.buttons .item[data-v-21be7a6a]{padding:2.2rem;background-color:#f59b22;font-size:1.5rem!important;color:#fff;border-radius:12px;border:none}.buttons .item img[data-v-21be7a6a]{clear:both;vertical-align:middle;width:50px;height:50px;margin-right:10px}.buttons .item[data-v-21be7a6a]:last-child{background-color:#f45d60}.settingImg[data-v-21be7a6a]{width:32px;height:32px;cursor:pointer}.el-dropdown[data-v-21be7a6a]{position:absolute;right:30px;top:30px;z-index:1001}[data-v-21be7a6a] .el-dialog__header{background-color:#f2f2f2!important}[data-v-21be7a6a] .el-dialog__footer{border-top:1px solid #d7d7d7;padding:12px!important}", ""]);
// Exports
module.exports = exports;
