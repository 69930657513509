import modifypassword from '@index/components/modifypassword.vue';
import common from '@index/utils/common';
export default {
	name: 'transitionpage',
	components: {
		modifypassword
	},
	data() {
		return {
			domainInfo: {},
		};
	},
	watch: {
		$route: {
			handler: function() {

			},
			immediate: true
		}
	},
	created() {
		// window.addEventListener('storage', this.afterQRScan)
		// this.$router.go(0);
		if (sessionStorage.getItem("userPasswrodNullFlag") == 'YES') {
			this.openSetPasswd(); //打开密码修改框
		}

	},
	mounted() {
		this.init();
	},
	destroyed() {
		// window.removeEventListener('storage', this.afterQRScan)
	},
	methods: {
		init() {
			var temp = sessionStorage.getItem('domainInfo');
			this.domainInfo = temp? JSON.parse(temp) : {
				schemeName: '我是记分长'
			};
			if (this.domainInfo) {
				document.title = this.domainInfo.schemeName;
			}
		},

		toTeam() {
			this.$router.push({
				path: '/team'
			})
		},
		toMyMatchs() {
			this.$router.push({
				path: '/mymatchs'
			})
		},
		headderCommand(command) {
			switch (command) {
				case "modifyPassword":
					this.$refs.modifypassword.openDialog();
					break;
				case "out":
					common.signout();
					break;
			}
		}

	}
}